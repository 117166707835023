export const IMAGE_EXTENSIONS = ["image/gif", "image/jpeg", "image/png"];
export const tusPort = process.env.NODE_ENV === "development" ? ":1080" : "";
export const tusEndpoint = `${window.location.protocol}//${window.location.hostname}${tusPort}/files/`;
export const PRACTICE_CLASS_ID = 333;
export const CONNECTIVITY_TEST_ID = 364;
export const DEMO_CLASS_ID = 53;

export const MINIMUM_BILL_AMOUNTS = {
  USD: 5,
  PKR: 600,
};
export const MAX_BILL_DAYS = 42; // days
export const MIN_BILL_DAYS = 7; // days
export const UNUSUAL_BILL_DURATION = 35; // days

export const MAXIMUM_FILE_UPLOADS = 20;
